import { HandleClickOutside, LinkProps } from 'aresui';
import { useBoolean, useWindowSize } from 'codekit';
import { motion } from 'framer-motion';

import { ShineBorder } from 'components/ShineBorder';
import { mobileWarning } from 'constants/warning';
import { Icons } from 'icons';
import Button from 'layouts/Institutional/Button';

import { BannerContentContainer } from './styles';

function BannerContent() {
	// Hooks
	const size = useWindowSize();

	// Boolean hooks
	const isActiveVideoModal = useBoolean();

	return (
		<BannerContentContainer>
			<motion.div
				className="text"
				initial={{
					y: -30,
					opacity: 0,
				}}
				animate={{
					y: 0,
					opacity: 1,
				}}
				transition={{
					duration: 0.5,
				}}
			>
				<div onClick={isActiveVideoModal.setTrue}>
					<ShineBorder
						className="tag"
						color={[
							'rgba(255, 255, 255, .6)',
							'rgba(255, 255, 255, 0)',
						]}
						borderRadius={30}
						duration={10}
					>
						<Icons.LuPlay fill="#fff" />
						Ver como funciona
					</ShineBorder>
				</div>

				<motion.h1>
					Planilhe & classifique <br />
					balancetes em segundos
				</motion.h1>

				<motion.p>
					Simplifique o M&A com IA: converta, classifique e organize
					balancetes automaticamente.
				</motion.p>

				<motion.div
					className="buttons"
					initial={{
						y: -30,
						opacity: 0,
					}}
					animate={{
						y: 0,
						opacity: 1,
					}}
					transition={{
						duration: 0.5,
						delay: 0.5,
					}}
				>
					<Button
						variant="default"
						linkTo={!size.isMobile ? 'auth.register' : '#'}
						linkProps={
							{
								'aria-label':
									'Crie uma conta no balancete.ai e comece a usar gratuitamente',
							} as LinkProps
						}
						onClick={() =>
							size.isMobile ? alert(mobileWarning) : console.log
						}
					>
						Comece grátis{' '}
						<Icons.LuMoveRight className="move-right" />
					</Button>

					<a
						href="https://calendar.app.google/mEVaaJZ24eUCLzLu6"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Agendar conversa com o cofundador"
					>
						<Button variant="text">
							Agendar call <Icons.LuCalendarDays />
						</Button>
					</a>
				</motion.div>
			</motion.div>

			{isActiveVideoModal.value && (
				<motion.div
					className="video"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
				>
					<iframe
						width={560}
						height={315}
						src="https://www.youtube-nocookie.com/embed/-uoj2-_AaZ8?si=c1EufbOzzpByE0jW"
						title="YouTube video player"
						frameBorder={0}
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						referrerPolicy="strict-origin-when-cross-origin"
						allowFullScreen
						className="video-iframe"
					/>

					<HandleClickOutside
						selectors={['iframe.video-iframe']}
						onClickOutside={isActiveVideoModal.setFalse}
					/>
				</motion.div>
			)}
		</BannerContentContainer>
	);
}

export default BannerContent;
