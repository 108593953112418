import { LinkProps } from 'aresui';
import { useWindowSize } from 'codekit';

import { mobileWarning } from 'constants/warning';
import { Icons } from 'icons';
import Button from 'layouts/Institutional/Button';
import Tag from 'layouts/Institutional/Tag';

import { ContactUsContentContainer } from './styles';

function ContactUsContent() {
	// Hooks
	const size = useWindowSize();

	return (
		<ContactUsContentContainer>
			<div className="text">
				<Tag>Fale conosco</Tag>
				<h1>
					Otimize seu tempo. <br />
					Comece hoje.
				</h1>

				<div className="buttons">
					<a
						href="https://calendar.app.google/mEVaaJZ24eUCLzLu6"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Agendar conversa com o cofundador"
					>
						<Button variant="text">
							<Icons.LuCalendar />
							Fale conosco
						</Button>
					</a>

					<Button
						linkTo={!size.isMobile ? 'auth.register' : '#'}
						onClick={() =>
							size.isMobile ? alert(mobileWarning) : console.log
						}
						linkProps={
							{
								'aria-label':
									'Crie uma conta no balancete.ai e comece a usar gratuitamente',
							} as LinkProps
						}
					>
						Começar agora
						<Icons.LuMoveRight className="move-right" />
					</Button>
				</div>
			</div>
		</ContactUsContentContainer>
	);
}

export default ContactUsContent;
