import { Icons } from 'icons';
import Button from 'layouts/Institutional/Button';
import Tag from 'layouts/Institutional/Tag';

import { TalkToAExpertContentContainer } from './styles';

function TalkToAExpertContent() {
	return (
		<TalkToAExpertContentContainer>
			<div className="text">
				<Tag>AI para M&A</Tag>
				<h1>Conversor com AI</h1>
				<p>
					Independente do template ou do formato do arquivo, a IA
					consegue identificar todas as contas contábeis em um
					balancete, converter para planilha e ja classificar cada
					conta
				</p>

				<a
					href="https://calendar.app.google/mEVaaJZ24eUCLzLu6"
					target="_blank"
					rel="noopener noreferrer"
					aria-label="Agendar conversa com o cofundador"
				>
					<Button>
						Falar com um especialista{' '}
						<Icons.LuMoveRight className="move-right" />
					</Button>
				</a>
			</div>
		</TalkToAExpertContentContainer>
	);
}

export default TalkToAExpertContent;
